import * as React from "react";

import styled from "../../theme/styled";
import {
  colourHelper,
  fontFamilyHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import {
  FullWidthWrapper,
  MediumColumnWidthWrapper
} from "../confines-wrappers";
import Icon from "../icon";
import { IIcons } from "../icon/icon-list";
import Link from "../link";
import { HeadingUppercase } from "../heading";
import { IThemeColour } from "../../theme/theme-definition";

interface ITextPanelProps {
  className?: string;
  text: React.ReactNode;
  label?: string;
  labelAs?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  icon?: keyof IIcons;
  linkText?: string;
  linkUrl?: string;
  backgroundColor?: keyof IThemeColour;
}

interface ITextPanelStyled extends React.HTMLAttributes<HTMLDivElement> {
  backgroundColor?: ITextPanelProps["backgroundColor"];
}

const TextPanelContainer = styled<ITextPanelStyled>(FullWidthWrapper)`
  background-color: ${({ theme, backgroundColor }) =>
    colourHelper({
      theme,
      variant: backgroundColor || "lightestGrey"
    })};

  .description {
    transition: all 0.2s ease-in-out;
  }

  .description {
    padding-top: 1.8rem;

    p {
      margin-bottom: 0;
      padding-top: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};

      &:first-child {
        padding-top: 0;
      }
    }
  }

  .icon-wrapper {
    transition: 0.2s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 2.8rem;

    .icon {
      width: 6rem;
      height: 6rem;
    }

    a {
      display: inline-block;
      margin-left: 14%;
      padding-bottom: 0.2em;
      font-family: ${({ theme }) =>
    fontFamilyHelper({
      theme,
      variant: "regular"
    })};
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
    }
  }

  @media (min-width: 480px) {
    .icon-wrapper {
      a {
        margin-left: 5.6rem;
      }
    }
  }

  @media (min-width: 600px) {
    .description {
      p {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
      }
    }

    .icon-wrapper {
      padding-top: 4.2rem;

      .icon {
        width: 8.8rem;
        height: 8.8rem;
      }
    }
  }

  @media (min-width: 768px) {
    .description {
      padding-top: 2.4rem;

      p {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
      }
    }

    .icon-wrapper {
      padding-top: 4.2rem;
    }
  }

  @media (min-width: 1024px) {
    .description {
      max-width: 100rem;

      p {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
      }
    }

    .icon-wrapper {
      padding-top: 6.8rem;
    }
  }
`;

const TextPanel: React.FC<ITextPanelProps> = ({
  className,
  label,
  labelAs,
  text,
  icon,
  linkText,
  linkUrl,
  backgroundColor
}) => {
  // If a linkUrl is provided, strip any CMS meta
  if (linkUrl) {
    linkUrl = linkUrl.replace(/internal:|external:/i, "");
  }

  return (
    <TextPanelContainer
      className={`content-container ${className ? className : ""}`}
      backgroundColor={backgroundColor}
    >
      <MediumColumnWidthWrapper>
        <HeadingUppercase
          as={labelAs || "p"}
          color="brandColor"
        >
          {label}
        </HeadingUppercase>

        <div className="description">
          {text}
        </div>

        <div className="icon-wrapper">
          {icon && (
            <Icon
              className="icon"
              icon={icon}
              size={8}
            />
          )}

          {linkText && linkUrl && (
            <Link
              href={linkUrl}
              type="styled"
            >
              {linkText}
            </Link>
          )}
        </div>
      </MediumColumnWidthWrapper>
    </TextPanelContainer>
  );
};

export default TextPanel;
