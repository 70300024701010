import React from "react";

import LifetimeTrainingImage from "../../assets/images/customer-portals/lifetime-ipad.jpg";
import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import { FullWidthColumnWrapper } from "../confines-wrappers";
import Link from "../link/link";
import Panel from "../panel-wrapper";

const CaseStudyContainer = styled.div`
  overflow: hidden;

  .case-study-wrapper {
    .case-study-content {
      transition: 0.2s ease-in-out;
      position: relative;

      .case-study-copy-wrapper {
        position: relative;
        z-index: 2;

        h2,
        p {
          text-align: center;
        }

        h2 {
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
          letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
          text-transform: uppercase;
        }

        .copy {
          padding-top: 2.6rem;
          padding-left: 2.8rem;
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};

          li {
            padding-top: 0.6em;

            &:first-child {
              padding-top: 0;
            }
          }
        }

        .link {
          padding-top: 3.6rem;
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "regular"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }
      }
    }
  }

  @media (min-width: 480px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .link {
            padding-top: 4.2rem;
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          h2 {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }

          .copy {
            padding-top: 3.2rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
          }

          .link {
            padding-top: 6.2rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .case-study-wrapper {
      .case-study-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 2%;
        margin-bottom: 2%;
        padding-top: 0;

        .case-study-copy-wrapper {
          width: 50%;
        }

        .case-study-copy-wrapper {
          padding-right: 4%;

          h2,
          p {
            text-align: left;
          }

          h2 {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }

          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          }

          .link {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            padding-top: 3.8rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
          }

          .link {
            padding-top: 6.8rem;
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_3"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1920px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
          }
        }
      }
    }
  }
`;

const LifetimeCaseStudy = () => (
  <CaseStudyContainer className="case-study-container">
    <Panel
      gradient="lifetimeBlue"
      backgroundImage={LifetimeTrainingImage}
      parallax
    >
      <FullWidthColumnWrapper className="case-study-wrapper content-container">
        <div className="case-study-content">
          <div className="case-study-copy-wrapper">
            <h2>
              Lifetime Training - Pulse Customer Portal
            </h2>

            <ul className="copy">
              <li>
                Training progress portal for 1000s of trainees.
              </li>

              <li>
                Used by dozens of major brands to monitor learning.
              </li>

              <li>
                Responsive web app, designed for mobile and desktop.
              </li>

              <li>
                Unique access for trainees, educators, managers and directors.
              </li>

              <li>
                Explore data and drill down through multiple levels.
              </li>

              <li>
                Simple and customised charts and graphs.
              </li>
            </ul>

            <p className="link">
              <span className="off-screen">
                Click here to
                {" "}
              </span>

              <Link
                type="styled"
                color="white"
                href="/projects/lifetime-training-web-app"
              >
                View Project
              </Link>
            </p>
          </div>
        </div>
      </FullWidthColumnWrapper>
    </Panel>
  </CaseStudyContainer>
);

export { LifetimeCaseStudy };
