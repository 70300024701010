import React from "react";
import { Parallax } from "react-scroll-parallax";

import AdelieTablet from "../../assets/images/customer-portals/adelie-ipad.png";
import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import { FullWidthColumnWrapper } from "../confines-wrappers";
import Link from "../link";
import Panel from "../panel-wrapper";

const CaseStudyContainer = styled.div`
  overflow: hidden;

  .case-study-wrapper {
    .case-study-content {
      transition: 0.2s ease-in-out;
      position: relative;
      padding-top: 84%;

      .case-study-copy-wrapper {
        position: relative;
        z-index: 2;

        h2,
        p {
          text-align: center;
        }

        h2 {
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
          letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
          text-transform: uppercase;
        }

        .copy {
          padding-top: 2.6rem;
          padding-left: 2.8rem;
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};

          li {
            padding-top: 0.6em;

            &:first-child {
              padding-top: 0;
            }
          }
        }

        .link {
          padding-top: 3.6rem;
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "regular"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }
      }

      .case-study-parallax-container {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        padding-top: 70%;
        width: 100%;

        .case-study-parallax-wrapper {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 86%;

          .adelie-ipad {
            > div {
              border-radius: 5%;
              box-shadow: 0 0 3.2em 0 rgba(0, 0, 0, 0.6);
            }
          }
        }
      }
    }
  }

  @media (min-width: 480px) {
    .case-study-wrapper {
      .case-study-content {
        padding-top: 80%;

        .case-study-copy-wrapper {
          .link {
            padding-top: 4.2rem;
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    .case-study-wrapper {
      .case-study-content {
        padding-top: 78%;

        .case-study-copy-wrapper {
          h2 {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }

          .copy {
            padding-top: 3.2rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
          }

          .link {
            padding-top: 6.2rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }
        }

        .case-study-parallax-container {
          .case-study-parallax-wrapper {
            .adelie-ipad {
              > div {
                box-shadow: 0 0 6.4em 0 rgba(0, 0, 0, 0.6);
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .case-study-wrapper {
      .case-study-content {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding-top: 0;

        .case-study-copy-wrapper,
        .case-study-parallax-container {
          width: 50%;
        }

        .case-study-copy-wrapper {
          padding-left: 4%;

          h2,
          p {
            text-align: left;
          }

          h2 {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }

          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          }

          .link {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }
        }

        .case-study-parallax-container {
          position: relative;
          margin-top: 2%;
          margin-bottom: 2%;
          padding-top: 34%;
          padding-right: 4%;

          .case-study-parallax-wrapper {
            .adelie-ipad {
              > div {
                box-shadow: 0 0 3.2em 0 rgba(0, 0, 0, 0.6);
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            padding-top: 3.8rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
          }

          .link {
            padding-top: 6.8rem;
          }
        }

        .case-study-parallax-container {
          .case-study-parallax-wrapper {
            .adelie-ipad {
              > div {
                box-shadow: 0 0 6.4rem 0 rgba(0, 0, 0, 0.6);
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_3"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1920px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
          }
        }

        .case-study-parallax-container {
          .case-study-parallax-wrapper {
            left: 0;
            transform: none;
            width: 92%;
          }
        }
      }
    }
  }
`;

const AdelieCaseStudy = () => (
  <CaseStudyContainer className="case-study-container">
    <Panel gradient="adelieBlueGreen">
      <FullWidthColumnWrapper className="case-study-wrapper content-container">
        <div className="case-study-content">
          <div className="case-study-copy-wrapper">
            <h2>
              Adelie Customer Portal
            </h2>

            <ul className="copy">
              <li>
                Self-service and ordering portal - connects to existing ERP.
              </li>

              <li>
                £14m of order value in 2018, 10,000s of stores, 100s of
                household names.
              </li>

              <li>
                High quality customer experience.
              </li>

              <li>
                Live ordering, scheduled orders, cut-offs, EDI and bulk uploads.
              </li>

              <li>
                Multiple delivery points, multiple users.
              </li>

              <li>
                Reporting, customer services, Sale or Return.
              </li>

              <li>
                Any device, any time, always quick and easy.
              </li>
            </ul>

            <p className="link">
              <span className="off-screen">
                Click here to
                {" "}
              </span>

              <Link
                type="styled"
                color="white"
                href="/projects/adelie-foods"
              >
                View Project
              </Link>
            </p>
          </div>

          <div className="case-study-parallax-container">
            <div className="case-study-parallax-wrapper">
              <Parallax
                className="adelie-ipad"
                y={[ -10, 20 ]}
                tagOuter="figure"
              >
                <img
                  className="full"
                  src={AdelieTablet}
                  alt="Adelie demo tablet"
                />
              </Parallax>
            </div>
          </div>
        </div>
      </FullWidthColumnWrapper>
    </Panel>
  </CaseStudyContainer>
);

export { AdelieCaseStudy };
