import React from "react";

import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import { SmallColumnWidthWrapper } from "../confines-wrappers";
import Icon from "../icon";

interface IProps {
  content: ITestimonial;
}

export interface ITestimonial {
  testimonial: string;
  name: string;
  role: string;
  company: string;
  company_logo?: string;
}

const TestimonialWrapper = styled(SmallColumnWidthWrapper)`
  transition: 0.2s ease-in-out;
  position: relative;
  padding-top: 5.8rem;

  .icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    fill: ${({ theme }) => colourHelper({
    theme,
    variant: "brandColor"
  })};
  }

  .logo-wrapper {
    transition: 0.2s ease-in-out;
    padding-bottom: 3.8rem;

    .logo {
      margin: 0 auto;
      width: 18rem;
    }
  }

  .testimonial-text {
    text-align: center;

    span {
      display: block;
    }

    .testimonial {
      transition: 0.2s ease-in-out;
      color: ${({ theme }) => colourHelper({
    theme,
    variant: "black"
  })};
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_2"
  })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
      font-style: italic;
    }

    .testimonial-by,
    .testimonial-role-company {
      color: ${({ theme }) => colourHelper({
    theme,
    variant: "lightGrey"
  })};
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_2"
    })};
      letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
      text-transform: uppercase;
    }

    .testimonial-by {
      transition: 0.2s ease-in-out;
      padding-top: 3.2rem;
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "bold"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "normalized"
    })};

      cite {
        font-style: normal;
      }
    }

    .testimonial-role-company {
      transition: 0.2s ease-in-out;
      padding-top: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "large"
    })};

      .separator {
        display: none;
      }
    }
  }

  @media (min-width: 600px) {
    padding-top: 8.4rem;

    .logo-wrapper {
      padding-bottom: 5.2rem;
    }

    .testimonial-text {
      .testimonial {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
      }

      .testimonial-by {
        padding-top: 4.6rem;
      }

      .testimonial-role-company {
        line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "normalized"
    })};

        .role,
        .separator,
        .company {
          display: inline;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .testimonial-text {
      .testimonial {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
      }

      .testimonial-by,
      .testimonial-role-company {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
      }
    }
  }

  @media (min-width: 960px) {
    padding-top: 12.8rem;

    .logo-wrapper {
      padding-bottom: 5.8rem;
    }

    .testimonial-text {
      .testimonial {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_3"
    })};
      }

      .testimonial-by {
        padding-top: 5.8rem;
      }
    }
  }

  @media (min-width: 1280px) {
    padding-top: 14.8rem;

    .logo-wrapper {
      padding-bottom: 6.2rem;
    }

    .testimonial-text {
      .testimonial-by {
        padding-top: 6.2rem;
      }
    }
  }
`;

const Testimonial = ({ content }: IProps) => {
  return (
    <TestimonialWrapper
      tagName="blockquote"
      className="testimonial-wrapper"
    >
      <Icon
        className="icon"
        icon="speechMarks"
        size={1.6}
      />

      {content.company_logo && (
        <div className="logo-wrapper">
          <img
            className="logo"
            src={content.company_logo}
            alt={content.company}
          />
        </div>
      )}

      <p className="testimonial-text">
        <span className="testimonial">
          {content.testimonial}
        </span>

        <span className="off-screen">
          {" Written by "}
        </span>

        <span className="testimonial-by">
          <cite>
            {content.name}
          </cite>
        </span>

        <span className="off-screen">
          {" "}
          (
        </span>

        <span className="testimonial-role-company">
          <span className="role">
            {content.role}
          </span>

          <span className="separator">
            {" // "}
          </span>

          <span className="company">
            {content.company}
          </span>
        </span>

        <span className="off-screen">
          )
        </span>
      </p>
    </TestimonialWrapper>
  );
};

export default Testimonial;
