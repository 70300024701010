import * as React from "react";

import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import { FullWidthColumnWrapper } from "../confines-wrappers";
import { HeadingUnderlined } from "../heading";
import Panel from "../panel-wrapper";

const CaseStudyContainer = styled.div`
  overflow: hidden;

  .case-study-wrapper {
    .case-study-content {
      transition: 0.2s ease-in-out;
      position: relative;

      .case-study-copy-wrapper {
        position: relative;
        z-index: 2;

        h2,
        p {
          text-align: center;
        }

        h2 {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
        }

        .copy {
          padding-left: 2.8rem;
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};

          li {
            padding-top: 0.6em;

            &:first-child {
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          h2 {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
          }

          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
          }

          .link {
            padding-top: 6.2rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .case-study-wrapper {
      .case-study-content {
        padding-top: 0;

        .case-study-copy-wrapper {
          h2 {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
          }

          .copy {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 46rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};

            li {
              width: 44%;

              &:first-child {
                padding-top: 0.6em;
              }

              &:nth-child(1n + 6) {
                margin-left: 10%;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          h2 {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
          }

          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};

            li {
              width: 42%;

              &:nth-child(1n + 5) {
                margin-left: 14%;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          h2 {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
          }

          .copy {
            height: 50rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_3"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            height: 60rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1920px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            height: 70rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
          }
        }
      }
    }
  }
`;

const ServicesAndFeatures = () => (
  <CaseStudyContainer className="case-study-container">
    <Panel gradient="purpleOrange">
      <FullWidthColumnWrapper className="case-study-wrapper content-container">
        <div className="case-study-content">
          <div className="case-study-copy-wrapper">
            <HeadingUnderlined
              headingTag="h2"
              title="Services &amp; features"
              textColor="white"
              hrColor="white"
            />

            <ul className="copy content-container">
              <li>
                eCommerce, ordering and online payment.
              </li>

              <li>
                ERP integration.
              </li>

              <li>
                Self-service & Customer service features.
              </li>

              <li>
                Reporting.
              </li>

              <li>
                Native apps and web-apps.
              </li>

              <li>
                Modern, responsive web design.
              </li>

              <li>
                Customisation options.
              </li>

              <li>
                Software for B2B & B2C.
              </li>

              <li>
                Marketing and on-boarding support.
              </li>
            </ul>
          </div>
        </div>
      </FullWidthColumnWrapper>
    </Panel>
  </CaseStudyContainer>
);

export default ServicesAndFeatures;
