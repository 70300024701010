import React from "react";

import AdeliLogo from "../../assets/images/customer-portals/adelie-logo.png";
import SableAndOxLogo from "../../assets/images/customer-portals/sableandox-logo.png";
import WPDLogo from "../../assets/images/customer-portals/western-power-distribution-logo.png";
import TestimonialCarousel from "../testimonial-carousel";

const testimonials = [
  {
    company_logo: AdeliLogo,
    company: "Adelie",
    name: "Matthew Arnott",
    role: "Head of Business Systems & Service Delivery",
    testimonial:
      "Gravitywell managed to understand our existing complex business needs and to anticipate future ones. They delivered the right technology for the project but also brought a design and UX consideration that is rarely seen at enterprise-level. We're very happy with their impressive effort, pro-active attitude and general responsiveness so we expect to continue the positive relationship in the future."
  },
  {
    company_logo: WPDLogo,
    company: "Western Power Distribution",
    name: "Emma Vincent",
    role: "Marketing Manager",
    testimonial:
      "In launching a new App, we were keen to get involved with a local company that understood what we wanted and could work alongside our existing internal systems. The project was a lot more technical than we had originally anticipated but thanks to Gravitywell we have been able to provide an extremely useful customer service tool which has been well received."
  },
  {
    company_logo: SableAndOxLogo,
    company: "Sable & Ox",
    name: "Billy Williams",
    role: "CEO",
    testimonial:
      "Working with the Gravitywell team has been a very rewarding and enjoyable experience. Their attention to detail and professional expertise was only surpassed by their unfailing “can do” attitude"
  }
];

const Testimonial = () => (
  <>
    <h2 className="off-screen">
      Testimonials
    </h2>

    <TestimonialCarousel content={testimonials} />
  </>
);

export { Testimonial };
