import React from "react";
import SlickCarousel from "react-slick";

import styled from "../../theme/styled";
import { colourHelper } from "../../theme/theme-helper";
import Icon from "../icon";
import { IThemeColour } from "../../theme/theme-definition";
import Testimonial, { ITestimonial } from "./testimonial";

interface IProps {
  className?: string;
  backgroundColor?: keyof IThemeColour;
  content: ITestimonial[];
}

interface ITestimonialCarouselStyled {
  backgroundColor?: IProps["backgroundColor"];
}

const TestimonialCarouselWrapper = styled<ITestimonialCarouselStyled, "div">(
  "div"
)`
  @import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css";

  .content-container {
    margin: 0;
    list-style: none;
    background-color: ${({ theme, backgroundColor }) =>
    colourHelper({
      theme,
      variant: backgroundColor || "lightestGrey"
    })};

    .slick-multi-items {
      padding-top: 0 !important;

      button {
        display: block;
        text-indent: -99999rem;
        border: 0;
        outline: 0;
      }

      .slick-arrow {
        transition: 0.2s ease-in-out;
        display: none !important;
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        padding: 1rem;
        cursor: pointer;

        &.slick-prev {
          left: 1%;
        }

        &.slick-next {
          right: 1%;
        }

        .icon {
          fill: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
        }

        &.slick-disabled {
          cursor: default;
          opacity: 0.1;

          .icon {
            fill: ${({ theme }) => colourHelper({
    theme,
    variant: "black"
  })};
          }
        }
      }

      .slick-dots {
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0;
        list-style: none;
        cursor: pointer;

        li {
          display: block;
          margin: 0;
          padding: 0 0.3rem;

          button {
            opacity: 0.2;
            width: 0.9rem;
            height: 0.9rem;
            background-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "black"
    })};
            border-radius: 100%;
          }

          &.slick-active {
            button {
              opacity: 0.6;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .content-container {
      .slick-multi-items {
        .slick-arrow {
          display: block !important;
        }
      }
    }
  }

  @media (min-width: 1148px) {
    .content-container {
      .slick-multi-items {
        .slick-arrow {
          &.slick-prev {
            left: 3%;
          }

          &.slick-next {
            right: 3%;
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .content-container {
      .slick-multi-items {
        .slick-arrow {
          &.slick-prev {
            left: 6%;
          }

          &.slick-next {
            right: 6%;
          }
        }
      }
    }
  }

  @media (min-width: 1680px) {
    .content-container {
      .slick-multi-items {
        .slick-arrow {
          &.slick-prev {
            left: 10%;
          }

          &.slick-next {
            right: 10%;
          }
        }
      }
    }
  }

  @media (min-width: 1920px) {
    .content-container {
      .slick-multi-items {
        .slick-arrow {
          &.slick-prev {
            left: 16%;
          }

          &.slick-next {
            right: 16%;
          }
        }
      }
    }
  }

  @media (min-width: 2164px) {
    .content-container {
      .slick-multi-items {
        .slick-arrow {
          &.slick-prev {
            left: 20%;
          }

          &.slick-next {
            right: 20%;
          }
        }
      }
    }
  }

  @media (min-width: 2520px) {
    .content-container {
      .slick-multi-items {
        .slick-arrow {
          &.slick-prev {
            left: 24%;
          }

          &.slick-next {
            right: 24%;
          }
        }
      }
    }
  }
`;

const TestimonialCarousel = ({
  className,
  backgroundColor,
  content
}: IProps) => {
  return (
    <TestimonialCarouselWrapper
      className={`testimonial-carousel-container ${
        className ? ` ${className}` : ""
      }`}
      backgroundColor={backgroundColor}
    >
      <div className="content-container">
        <SlickCarousel
          className={content.length > 1 ? "content-container slick-multi-items" : ""}
          arrows
          prevArrow={(
            <span>
              <Icon
                className="icon"
                icon="arrowLeft"
                size={2}
              />
            </span>
          )}
          nextArrow={(
            <span>
              <Icon
                className="icon"
                icon="arrowRight"
                size={2}
              />
            </span>
          )}
          dots
          infinite={false}
          speed={400}
          slidesToShow={1}
          slidesToScroll={1}
        >
          {content.map((testimonial, i) => {
            return (
              <Testimonial
                key={i}
                content={testimonial}
              />
            );
          })}
        </SlickCarousel>
      </div>
    </TestimonialCarouselWrapper>
  );
};

export default TestimonialCarousel;
