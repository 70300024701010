import * as React from "react";

import Link from "../link";
import { default as GetInTouch } from "./get-in-touch";

interface IGetInTouchProps {
  title?: string;
  slogan?: string;
  links?: IGetInTouchLinkProps[];
}

export interface IGetInTouchLinkProps {
  href: string;
  text: string;
}

/**
 * Get in touch wrapper
 */
const GetInTouchWrapper: React.FC<IGetInTouchProps> = ({
  title,
  slogan,
  links
}) => {
  const parsedLinks: IGetInTouchLinkProps[] = React.useMemo(() => {
    const defaultLinks = [
      {
        href: "/insights",
        text: "Insights"
      },
      {
        href: "/about-us",
        text: "Meet the team"
      }
    ];

    return links || defaultLinks;
  }, [ links ]);

  return (
    <GetInTouch
      title={title || "Get in Touch"}
      slogan={slogan || "Got a great idea for a project?"}
      links={parsedLinks.map((link, i) => (
        <Link
          key={i}
          href={link.href}
        >
          {link.text}
        </Link>
      ))}
    />
  );
};

export default GetInTouchWrapper;
