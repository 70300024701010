import * as React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

import styled from "../../theme/styled";
import { HeadingUnderlined } from "../heading";
import Icon from "../icon";
import Link from "../link";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import {
  FullWidthWrapper,
  MediumColumnWidthWrapper
} from "../confines-wrappers";

interface IProps {
  title: string;
  slogan: string;
  links: React.ReactNode[];
}

declare global {
  // allow Tally to be used in window
  interface Window { Tally: any; }
}

const GetInTouch: React.FC<IProps> = ({
  title, slogan, links
}) => {
  // Load Tally embeds
  useEffect(() => {
    if (window.Tally) {
      window.Tally.loadEmbeds();
    }
  }, []);

  return (
    <GetInTouchContainer
      className="content-container"
      id="get-in-touch"
    >
      <MediumColumnWidthWrapper>
        <HeadingUnderlined
          headingTag="h2"
          title={title}
        />

        <div className="column-container">
          <div className="column">
            <p className="got-an-idea">
              {slogan}

              <br />
              Let’s talk!

              <br />

              <span className="contact-details">
                <span className="off-screen">
                  Call us on:
                  {" "}
                </span>

                <Link
                  className="telephone"
                  href="tel:+441173790309"
                  type="inline"
                >
                  +44 (0)117 379 0309
                </Link>

                <br />

                <span className="off-screen">
                  Or email us:
                  {" "}
                </span>

                <Link
                  className="email"
                  href="mailto:hello@gravitywell.co.uk"
                  type="inline"
                >
                  hello@gravitywell.co.uk
                </Link>
              </span>

              <br />

              <span className="off-screen">
                Want to
                {" "}
              </span>

              <span className="learn-more">
                Learn more?
              </span>
            </p>

            <ul className="learn-more-links">
              {links.map((link, i) => (
                <li key={i}>
                  {link}
                </li>
              ))}
            </ul>

            <span className="icon">
              <Icon
                icon="lightbulb"
                size={4.2}
              />
            </span>
          </div>

          <div className="column">
            <div className="form-container">
              <Helmet>
                <script src="https://tally.so/widgets/embed.js" />
              </Helmet>

              <iframe
                data-tally-src="https://tally.so/embed/nWrPga?hideTitle=1&transparentBackground=1&dynamicHeight=1"
                loading="lazy"
                width="100%"
                height="762"
                frameBorder="0"
                title="Gravitywell Website Contact Form"
              />
            </div>
          </div>
        </div>
      </MediumColumnWidthWrapper>
    </GetInTouchContainer>
  );
};

export default GetInTouch;

const GetInTouchContainer = styled(FullWidthWrapper)`
  background-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "altLightGrey"
    })};

  .column-container {
    transition: 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10%;

    .form-container {
      display: flex;
      width: 100%;
    }

    .column {
      transition: 0.2s ease-in-out;
      padding-top: 7.2rem;
      width: 100%;
      max-width: 42rem;

      &:first-child {
        position: relative;
        padding-top: 0;
      }
      

      .got-an-idea {
        margin-bottom: 0;
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
        font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
        line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "large"
    })};

        .contact-details {
          display: block;
          color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};

          .telephone,
          .email {
            display: inline-block;
          }

          .telephone {
            padding-top: 1.2em;
          }

          .email {
            padding-top: 0.4rem;
          }
        }

        .learn-more {
          display: block;
          padding-top: 2.2rem;
        }
      }

      .learn-more-links {
        padding-top: 2.2rem;
        padding-left: 0;
        padding-bottom: 0;
        list-style: none;
        color: ${({ theme }) => colourHelper({
    theme,
    variant: "brandColor"
  })};
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
        font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
        line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "large"
    })};

        li {
          padding-top: 1.2rem;
          margin-bottom: 0;

          a {
            color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
          }
        }
      }

      .icon {
        display: none;
        position: absolute;
        top: -1.2rem;
        left: 0;
      }

      iframe {
        width: 100% !important;
      }
    }
  }

  @media (min-width: 600px) {
    .column-container {
      padding-top: 5.2rem;
    }
  }

  @media (min-width: 768px) {
    .column-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: normal;
      padding-top: 7.2rem;

      .column {
        padding-top: 0;
        width: 50%;
        max-width: none;

        &:first-child {
          padding-left: 5.6rem;
          padding-right: 2%;
        }

        &:last-child {
          padding-left: 2%;
          max-width: 50rem;
        }

        .icon {
          display: block;
        }
      }
    }
  }

  @media (min-width: 960px) {
    .column-container {
      padding-top: 9.8rem;

      .column {
        &:first-child {
          padding-left: 7.2rem;
        }

        .got-an-idea {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};

          .contact-details {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};

            .email {
              padding-top: 0.4rem;
            }
          }
        }

        .learn-more-links {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .column-container {
      .column {
        .got-an-idea {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};

          .contact-details {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};

            .email {
              padding-top: 0.4rem;
            }
          }
        }

        .learn-more-links {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
        }
      }
    }
  }
`;
