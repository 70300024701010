import React from "react";
import styled from "styled-components";

import AdelieWarehouse from "../assets/images/customer-portals/adelie-warehouse.jpg";
import VideoBackgroundImage from "../assets/images/customer-portals/customer-portal-fallback-image.jpg";
import { FullWidthColumnWrapper } from "../components/confines-wrappers";
import CtaButton from "../components/cta/cta-button";
import {
  AdelieCaseStudy,
  LifetimeCaseStudy,
  ServicesAndFeatures,
  Testimonial
} from "../components/customer-portals";
import Footer from "../components/footer";
import GetInTouch from "../components/get-in-touch";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import HeroVideo from "../components/hero-video";
import { breadCrumbSchema } from "../components/html-schema-snippets";
import Layout from "../components/layout";
import PageTop from "../components/page-top";
import TextPanel from "../components/text-panel";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../theme/theme-helper";

const PageTopCustom = styled.div`
  .top-content-container {
    .top-content-wrapper {
      position: absolute;
      left: 0;
      top: 0;

      .top-content {
        z-index: 3;
        text-align: center;

        .heading {
          transition: 0.2s ease-in-out;
          display: block;
          margin: 0 auto;
          width: 28rem;
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }

        .cta-button {
          padding-top: 6%;
        }
      }
    }
  }

  @media (min-width: 375px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 32rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
          }

          .cta-button {
            padding-top: 6%;
          }
        }
      }
    }
  }

  @media (min-width: 480px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 40rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 568px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 48rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 54rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 70rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_4"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 80rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_5"
    })};
          }

          .cta-button {
            padding-top: 5.2rem;
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .top-content-container {
      .top-content-wrapper {
        .appster-award,
        .media-innovation-award {
          width: 16%;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 88rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_6"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 98rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_7"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1920px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 118rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_9"
    })};
          }
        }
      }
    }
  }
`;

const CustomerPortalPage = () => {
  const canonicalPath = "/customer-portals";

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "Customer Portals",
      path: canonicalPath
    }
  ]);

  const schemaJson = [ breadcrumbsSchemaData ];

  return (
    <Layout>
      <>
        <HelmetWrapper
          title="Customer Portals - Self Service"
          description="Build better relationships with your customers"
          canonicalPath={canonicalPath}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header role="banner">
          <Header
            initialLogo
            initialTheme="dark"
          />

          <PageTopCustom id="hero">
            <PageTop
              style={{
                paddingTop: 0,
                paddingBottom: 0
              }}
              height="large"
            >
              <HeroVideo
                src="https://cdn.gravitywell.co.uk/videos/customer-portals/Landing-Page-Customer-Portal_03.m3u8"
                fallbackImage={VideoBackgroundImage}
              />

              <FullWidthColumnWrapper className="top-content">
                <p className="heading">
                  Build better relationships with your customers
                </p>

                <CtaButton
                  type="primary"
                  href="/contact-us"
                  text="Free quote"
                />
              </FullWidthColumnWrapper>
            </PageTop>
          </PageTopCustom>
        </header>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            Customer Portals
          </h1>

          <AdelieCaseStudy />

          <img
            src={AdelieWarehouse}
            alt="Adelie warehouse"
          />

          <aside>
            <TextPanel
              label="Working With Us"
              text={(
                <p>
                  We are an award winning agency with over 15 years experience
                  creating custom business applications.
                </p>
              )}
              icon="orbit"
            />
          </aside>

          <LifetimeCaseStudy />

          <aside>
            <TextPanel
              label="Working With Us"
              text={(
                <p>
                  We produce elegant software interfaces for 'real people', not
                  just the technical team.
                </p>
              )}
              icon="rocketLaunch"
            />
          </aside>

          <ServicesAndFeatures />

          <Testimonial />
        </main>

        <footer role="contentinfo">
          <GetInTouch />

          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export default CustomerPortalPage;
